/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

$('.gallery-item').magnificPopup({
  type: 'image',
  gallery:{
    enabled:true
  }
});

$.fn.slideFadeToggle  = function(speed, easing, callback) {
        return this.animate({opacity: 'toggle', height: 'toggle'}, 300);
}; 
    // Toggle open and close nav styles on click
    $('#nav-toggle').click(function(e) {
      e.preventDefault();
      $('.navigation-wrapper').slideFadeToggle();
      $(this).find('.nav-toggle-bars').toggleClass('active');
    });

$('.gallery-slider').slick({
  nextArrow: '<i class="fa fa-chevron-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fa fa-chevron-left slick-arrow arrow-left"></i>',
  autoplay: false,
  autoplaySpeed: 7000,
  fade: true,
});

$('.hero-slider').slick({
  autoplay: true,
  autoplaySpeed: 7000,
  fade: true,
  arrows: false
});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

function blokkenheight() {
   
  $('.news-list').each(function(){
  
    $('.news-list-block-content-height',this).height('auto');

        var highestBox = 0;
        $('.news-list-block-content-height', this).each(function(){

            if($(this).height() > highestBox) {
               highestBox = $(this).height(); 
             }
        });  

        $('.news-list-block-content-height',this).height(highestBox);

  });

  $('.blokken-list').each(function(){
  
    $('.blokken-list-block-content',this).height('auto');
      if ($(window).width() >= 768) {
        var highestBox = 0;
        $('.blokken-list-block-content', this).each(function(){

            if($(this).height() > highestBox) {
               highestBox = $(this).height(); 
             }
        });  

        $('.blokken-list-block-content',this).height(highestBox);
      }
  });

}
blokkenheight();

$(window).resize(function() {
  blokkenheight();

});

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
